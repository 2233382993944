export const AUTOBOTS_CALENDAR_VIEW = 'AUTOBOTS_CALENDAR_VIEW';
export const AUTOBOTS_SENSOR_DATA_EXPORTS = 'AUTOBOTS_SENSOR_DATA_EXPORTS';
export const AUTOBOTS_STAFF_SCHEDULE = 'AUTOBOTS_STAFF_SCHEDULE';
export const AUTOBOTS_VENDORS_CREATE_WORK_ORDERS = 'AUTOBOTS_VENDORS_CREATE_WORK_ORDERS';
export const CONFIRM_CONTACT_INFO = 'CONFIRM_CONTACT_INFO';
export const CORE_MARKET = 'CORE_MARKET';
export const DIRECT_ASSIGNMENT = 'DIRECT_ASSIGNMENT';
export const DISABLE_ISSUE_TREE = 'DISABLE_ISSUE_TREE';
export const INTERNAL_PREVENTATIVE_MAINTENANCE = 'INTERNAL_PREVENTATIVE_MAINTENANCE';
export const INTERNAL_PREVENTATIVE_MAINTENANCE_SCHEDULING =
    'INTERNAL_PREVENTATIVE_MAINTENANCE_SCHEDULING';
export const KUSTOMER_HELP = 'KUSTOMER_HELP';
export const KUSTOMER_WORKORDER_ESCALATIONS = 'KUSTOMER_WORKORDER_ESCALATIONS';
export const MONEY_AUTO_AP_BATCHING = 'MONEY_AUTO_AP_BATCHING';
export const MONEY_ORUM = 'MONEY_ORUM';
export const MONEY_RMC_AUTOPAY_SETTINGS = 'MONEY_RMC_AUTOPAY_SETTINGS';
export const MONEY_SUBSCRIPTIONS_V3_ANNUAL = 'MONEY_SUBSCRIPTIONS_V3_ANNUAL';
export const MONEY_SUBSCRIPTIONS_V3_START = 'MONEY_SUBSCRIPTIONS_V3_START';
export const NOTIFICATION_TEST_LIST = 'NOTIFICATION_TEST_LIST';
export const STAFF_SELECT_HIDE_ADMIN = 'STAFF_SELECT_HIDE_ADMIN';
export const VENDOR_CUSTOMERS = 'VENDOR_CUSTOMERS';
export const VENDOR_SOURCING_PARTS_ETA = 'VENDOR_SOURCING_PARTS_ETA';
export const VORTEX_OFF_RESQ_CUSTOMER_PORTAL = 'VORTEX_OFF_RESQ_CUSTOMER_PORTAL';
export const VORTEX_OTHER_CUSTOMERS = 'VORTEX_OTHER_CUSTOMERS';
export const VORTEX_SUBMIT_INVOICE = 'VORTEX_SUBMIT_INVOICE';
export const VULCAN_DISPATCH_TO_INVITED_VENDOR = 'VULCAN_DISPATCH_TO_INVITED_VENDOR';
export const VULCAN_FAST_PAY_INCENTIVE = 'VULCAN_FAST_PAY_INCENTIVE';
export const VULCAN_STORE_VENDOR_REMITTANCE_PDF = 'VULCAN_STORE_VENDOR_REMITTANCE_PDF';
export const VULCAN_VENDOR_MAINTENANCE_PLANS = 'VULCAN_VENDOR_MAINTENANCE_PLANS';
export const VULCAN_VERIFIED_VENDOR = 'VULCAN_VERIFIED_VENDOR';
export const WORK_ORDER_REVIEWS_NOTIFICATION = 'WORK_ORDER_REVIEWS_NOTIFICATION';
export const MONEY_ORUM_REFUNDS = 'MONEY_ORUM_REFUNDS';
export const VORTEX_INVOICE_SETS_PAGE = 'VORTEX_INVOICE_SETS_PAGE';
