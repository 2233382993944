import { Box, FormHelperText, styled, SvgIcon, SxProps, Theme } from '@mui/material';
import React from 'react';
import characters from 'src/constants/characters';
import { InformationCircleIcon } from 'src/icons';
import StyledTooltip from '../StyledTooltip';
import { Label } from './Label';

const Container = styled('div')<{ wide: boolean }>`
    ${({ wide }) => `
        width: ${wide ? '100%' : 'auto'};
    `}
`;

const Body = styled('div')``;

export interface FieldProps {
    editable?: boolean;
    error?: string | boolean | null | string[];
    helperText?: string;
    helperTextStyle?: SxProps<Theme>;
    label?: string;
    wide?: boolean;
    required?: boolean;
    tooltip?: React.ReactNode;
    labelAdornment?: React.ReactNode;
}
interface Parent {
    children: React.ReactNode;
}
export type FullFieldProps = FieldProps & Parent;

export type FormErrorTextProps = Pick<FieldProps, 'editable' | 'error'>;

export const FormErrorText: React.FC<FormErrorTextProps> = ({ editable, error }) => {
    if (!editable || !error || (Array.isArray(error) && !error.length)) {
        return null;
    }
    const errorStrings = Array.isArray(error) ? error : [error];
    return (
        <>
            {errorStrings
                .filter((e) => typeof e === 'string')
                .map((e, i) => {
                    return (
                        <FormHelperText error key={`form_error_${i}_${e}`}>
                            {e}
                        </FormHelperText>
                    );
                })}
        </>
    );
};

export const Field: React.FC<FullFieldProps> = ({
    editable = false,
    error,
    helperText,
    helperTextStyle,
    labelAdornment,
    label,
    tooltip,
    children,
    wide = false,
    required = false,
}) => {
    const isChildrenEmpty =
        children === '' ||
        children === null ||
        children === undefined ||
        children === false ||
        React.Children.count(children) === 0;

    return (
        <Container wide={wide}>
            <Box display="flex" justifyContent={labelAdornment ? 'space-between' : undefined}>
                {label && (
                    <Label
                        editable={editable}
                        error={!!error}
                        required={required}
                        inline={!!tooltip}
                    >
                        {label}
                    </Label>
                )}
                {labelAdornment}
                {tooltip && (
                    <Box marginLeft={0.5} marginTop={0.25}>
                        <StyledTooltip title={tooltip} placement="top-start">
                            <Box justifyContent="center" alignItems="center" fontSize="16px">
                                <SvgIcon fontSize="inherit">
                                    <InformationCircleIcon />
                                </SvgIcon>
                            </Box>
                        </StyledTooltip>
                    </Box>
                )}
            </Box>
            <Body>{isChildrenEmpty ? characters.emdash : children}</Body>
            <FormErrorText error={error} editable={editable} />
            {helperText && (
                <FormHelperText sx={{ marginTop: 1, ...helperTextStyle }}>
                    {helperText}
                </FormHelperText>
            )}
        </Container>
    );
};
